.App {
  text-align: center;
}

.header {
  display: flex; 
  justify-content: space-between;
  align-items: baseline;
  height: auto; 
  background-color: #f06d20;
}

.custom-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-card .ant-card-meta-title {
  margin-top: 10px; 
  white-space: normal;
}

.headerTitle {
  font-size: 25px;
}

.ant-radio {
  display: none!important;
}

.ant-image-preview-img {
  width: 80%!important;
  border: 5px solid #f06d20;
  border-radius: 10px;
}

.imgInPreview {
  margin: 10px; 
  width: 280px!important; 
  height: 150px!important; 
  border: 2px solid #f06d20;
}

aside {
  z-index: 1000!important;
}

.instructionSpan {
  color: #ff0000;
  font-weight: 600;
}

.paintFor {
  padding: 8px;
  border: 2px solid #ff0000;
}