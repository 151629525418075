body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #f06d20; 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
background-color: #f06d20; 
}

.ant-menu-item-divider {
  border-color: #fff !important;
  width: 80% !important;
  margin: 15px auto !important;
}

th.labelBG {
  background-color: #f2f3f4!important;
  /* border-color: #FFF!important; */
}

th.labelNone {
  display: none;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: #f06d20;
}


@keyframes blink {
  0% {
    /* opacity: 1; */
    transform: scale(1);
  }
  50% {
    /* opacity: 0.5; */
    transform: scale(1.1);
  }
  100% {
    /* opacity: 1; */
    transform: scale(1);
  }
}

.blinking {
  animation: blink 2s linear infinite;
}
